import { Controller } from "stimulus"
import { isEscapeKey } from "../src/helpers"

const CSS_CLASSES = {
  content: ".vendor-offer-edit-overlay-content"
}

export default class extends Controller {
  connect() {
    this.handleEscapeButton = this.handleEscapeButton.bind(this)

    window.addEventListener("popstate", this.close)
    document.addEventListener("keydown", this.handleEscapeButton)
  }

  disconnect() {
    window.removeEventListener("popstate", this.close)
    window.removeEventListener("keydown", this.handleEscapeButton)
  }

  close() { this.element.remove() }

  closeOnBackgroundClick(event) {
    if (event.target.closest(CSS_CLASSES.content)) { return }

    this.close()
  }

  handleEscapeButton(event) {
    if (isEscapeKey(event.key)) { this.close(event) }
  }
}
