import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'starInputMaterial', 'starLabelMaterial', 'starInputDelivery', 'starLabelDelivery', 'starInputCommunication', 'starLabelCommunication', 'starLabelTotal' ]

  connect() { this.highlightBasedOnSelection() }

  highlightBasedOnSelection(event) {
    if(event) {
      const element = event.currentTarget
      const detail = element.dataset.detail

      this.highlightStarsUntil(this.selectedNumberOfStars(detail), detail)
    } else {
      this.highlightStarsUntil(this.selectedNumberOfStars('Material'), 'Material')
      this.highlightStarsUntil(this.selectedNumberOfStars('Delivery'), 'Delivery')
      this.highlightStarsUntil(this.selectedNumberOfStars('Communication'), 'Communication')
    }

    this.setTotalStars()
  }

  highlightBasedOnHover(event) {
    const element = event.currentTarget
    const numberOfStars = parseInt(element.dataset.numberOfStars, 10)
    const detail = element.dataset.detail
    if (isNaN(numberOfStars)) { return }

    this.highlightStarsUntil(numberOfStars, detail)
  }

  highlightStarsUntil(numberOfStars, detail) {
    this['starLabel' + detail + 'Targets'].forEach(elem => {
      const currentNumberOfStars = parseInt(elem.dataset.numberOfStars, 10)
      if (isNaN(currentNumberOfStars) || currentNumberOfStars === 0) { return }

      elem.classList.toggle('is--active', currentNumberOfStars <= numberOfStars)
    })
  }

  setTotalStars() {
    const totalStars = Math.round((this.selectedNumberOfStars('Material') + this.selectedNumberOfStars('Delivery') + this.selectedNumberOfStars('Communication')) / 3)

    this.starLabelTotalTargets.forEach((elem, i) => {
      elem.classList.toggle('is--active', i + 1 <= totalStars)
    })
  }

  selectedInput(detail) {
    return this['starInput' + detail + 'Targets'].find(elem => elem.checked)
  }

  selectedNumberOfStars(detail) {
    if (!this.selectedInput(detail)) { return 0 }
    return parseInt(this.selectedInput(detail).dataset.numberOfStars, 10)
  }
}
