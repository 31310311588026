import { Controller } from "stimulus"

export default class extends Controller {
  clicked(event) {
    if (event.target.closest("[data-skip-link-element]")) { return }

    const data = this.element.dataset
    const url = data.url
    const options = {}

    if("modal" in data) {
      const frame = document.querySelector("turbo-frame#modal-frame")
      frame.src = url
    } else {
      if ("frame" in data) { options.frame = data.frame }
      Turbo.visit(url, options)
    }
  }
}
