import { Controller } from "stimulus"
import { euro, toDelocalizedFloat } from '../src/currency-helpers'

export default class extends Controller {
  static targets = ["vatInput", "totalInput"]
  static values = { netAmount: Number }

  updateTotalAmount() {
    if (!this.hasTotalInputTarget) { return }
    this.totalInputTarget.value = this.totalAmount.format()
  }

  get totalAmount() { return euro(this.netAmountValue).add(this.vatAmount) }

  get vatAmount() {
    if (!this.hasVatInputTarget) { return 0 }

    // Delocalize the input value
    const delocalizedValue = toDelocalizedFloat(this.vatInputTarget.value)
    if (isNaN(delocalizedValue)) { return euro(0) }
    if (delocalizedValue < 0) { return euro(0) }

    return euro(delocalizedValue)
  }
}
