import "@hotwired/turbo-rails"
import "@/controllers"
import "lazysizes"
import "lazysizes/plugins/unveilhooks/ls.unveilhooks"
import Rails from "@rails/ujs"
import debounced from "debounced"
import BSN from "bootstrap.native/dist/bootstrap-native.esm.min.js"

import I18nMock from "@/src/i18n_mock"

import "@/src/polyfills"

// Provides debounced event handlers/triggers, defaults to 200ms.
debounced.initialize({ ...debounced.events, input: { wait: 350 } })

// Depending on the load order of our chunks, rails-ujs might have already been loaded.
try { Rails.start() } catch(e) {}

// rails-ujs double click protection fails in Turbo Frames.
// According to https://github.com/hotwired/turbo-rails/issues/135 this should fix it.
Rails.delegate(document, Rails.linkDisableSelector, "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:submit-end", Rails.enableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-start", Rails.disableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-end", Rails.enableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:before-cache", Rails.enableElement)

// Ensure BootstrapNative handles new elements after a Turbo Drive navigation.
// This doesn't work for alerts rendered after a form submittal, though, so we use Stimulus for this.
// TODO: Check if we can remove BSN entirely and just use Stimulus. I think we only use Dropdowns and alerts, so we should be fine.
document.addEventListener("turbo:load", () => {
  BSN.initCallback(document.body)
  I18nMock.locale = document.body.locale
})
