import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['option', 'input', 'value']

  connect() {
    for(let option of this.optionTargets) {
      option.addEventListener('click', e => {
        e.preventDefault()

        this.select(option.dataset.value)
      })
    }
  }

  // disconnect() {
  //   for(let option of this.optionTargets)
  //     option.removeEventListener('click')
  // }

  select(option) {
    this.inputTarget.value = option
    this.valueTarget.innerHTML = option
  }
}
