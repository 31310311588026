import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'ratingDescription', 'ratingDescriptionFallback', 'starInput', 'starLabel' ]

  connect() { this.highlightBasedOnSelection() }

  highlightBasedOnSelection() {
    this.highlightStarsUntil(this.selectedNumberOfStars)
  }

  highlightBasedOnHover(event) {
    const element = event.currentTarget
    const numberOfStars = parseInt(element.dataset.numberOfStars, 10)
    if (isNaN(numberOfStars)) { return }

    this.highlightStarsUntil(numberOfStars)
  }

  highlightStarsUntil(numberOfStars) {
    this.starLabelTargets.forEach(elem => {
      const currentNumberOfStars = parseInt(elem.dataset.numberOfStars, 10)
      if (isNaN(currentNumberOfStars) || currentNumberOfStars === 0) { return }

      elem.classList.toggle('is--active', currentNumberOfStars <= numberOfStars)
    })

    // this.updateRatingDescription(numberOfStars)
  }

  updateRatingDescription(numberOfStars) {
    if (this.hasRatingDescriptionTarget) {
      this.ratingDescriptionTargets.forEach(elem => {
        const currentNumberOfStars = parseInt(elem.dataset.numberOfStars, 10)
        elem.classList.toggle('is--hidden', currentNumberOfStars != numberOfStars)
      })
    }

    if (!this.hasRatingDescriptionFallbackTarget) { return }

    if (!numberOfStars || isNaN(numberOfStars)) {
      this.ratingDescriptionFallbackTarget.classList.remove('is--hidden')
    } else {
      this.ratingDescriptionFallbackTarget.classList.add('is--hidden')
    }
  }

  get selectedInput() { return this.starInputTargets.find(elem => elem.checked) }
  get selectedNumberOfStars() {
    if (!this.selectedInput) { return 0 }
    return parseInt(this.selectedInput.dataset.numberOfStars, 10)
  }
}
