import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['pane']

  show() {
    this.paneTarget.style.display = 'block'
  }

  hide() {
    this.paneTarget.style.display = 'none'
  }

  toggle() {
    this.paneTarget.style.display !== 'block' ? this.show() : this.hide()
  }
}
