import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    email: String
  }

  connect() {
    if (!this.emailValue) { return }

    try {
      if (klaviyo) { klaviyo.push(['identify', {'$email' : this.emailValue}]) }
    } catch(e) {}
  }
}
