import { Controller } from "stimulus"
import EventBus   from "../src/event_bus"

export default class extends Controller {
  initialize() {
    this.updatePricePreview = this.updatePricePreview.bind(this)
  }

  connect() {
    EventBus.on("offer-price-preview:refresh", this.updatePricePreview)
  }

  disconnect() {
    EventBus.off("offer-price-preview:refresh", this.updatePricePreview)
  }

  handleKeyup() {
    if (!this.quantity) return
    if (this.quantity === this.prevValue) return

    this.updatePricePreview()
  }

  updatePricePreview() {
    let url = `${this.element.dataset.url}?quantity=${this.quantity}`

    if(this.element.dataset.inquiryId) { url += `&inquiry_id=${this.element.dataset.inquiryId}` }

    fetch(url, {
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      }
    }).then(r => r.text()).then(html => {
      Turbo.renderStreamMessage(html)
    })
  }

  get quantity() { return this.element.value }
}
