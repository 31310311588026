import { Controller } from 'stimulus'
import Rails          from '@rails/ujs'

const url = id => '/de/admin/inquiries/' + id + '/payment/'

export default class extends Controller {
  clicked() {
    if(this.classList.contains('btn-paid--checked')) {
      Rails.ajax({
        type: 'DELETE',
        url: url(this.dataset.id),
        success: () => { this.classList.remove('btn-paid--checked') },
        error: () => { console.log('An error occured :(') }
      })
    } else {
      Rails.ajax({
        type: 'POST',
        url: url(this.dataset.id),
        success: () => { this.classList.add('btn-paid--checked') },
        error: () => { console.log('An error occured :(') }
      })
    }
  }

  connect() {
    this.scope.element.addEventListener('click', this.clicked)
  }

  disconnect() {
    this.scope.element.removeEventListener('click', this.clicked)
  }
}
