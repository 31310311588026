import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    selector: String
  }

  connect() {
    if(this.targets.length > 0)
      this.element.addEventListener('change', this.onChange.bind(this))
  }

  onChange() {
    if(this.targets.length > 0) {
      if(this.element.value.length > 0) {
        for(const target of this.targets) target.disabled = false
      } else {
        for(const target of this.targets) target.disabled = true
      }
    }
  }

  get targets() { return document.querySelectorAll(this.selectorValue) }
}
