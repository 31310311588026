import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "productSeriesSelect",
    "changeAlert",
    "checkBoxInput",
    "checkBoxWrapper",
    "dataSheetCheckBox"
  ]

  // Lifecycle

  connect() {
    this.initialDataSheetState = this.dataSheetState
    this.updateUi()
  }

  // Entrypoints

  updateUi() {

    if (this.isSeriesSelected()) {
      this.checkBoxWrapperTargets.forEach(elem => elem.classList.remove("is--hidden"))
      return
    }

    this.checkBoxInputTargets.forEach(elem => elem.checked = false)
    this.checkBoxWrapperTargets.forEach(elem => elem.classList.add("is--hidden"))

    this.triggerChangeWarning()
  }

  triggerChangeWarning() {
    this.changeAlertTarget.classList.toggle("is--hidden", this.dataSheetState === this.initialDataSheetState)
  }

  isSeriesSelected() { return this.productSeriesSelectTarget.value !== "" }

  get dataSheetState() { return this.dataSheetCheckBoxTarget.checked }
}
