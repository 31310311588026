import { Controller } from "stimulus"

const DEFAULT_CSS_CLASS = "is--hidden"

export default class extends Controller {
  static values = {
    selector: String,
    cssClass: String
  }

  connect() {
    this.element.addEventListener("change", (event) => {
      this.element.value === "" ? this.show(event) : this.hide(event)
    })
  }

  hide(event) {
    event.stopPropagation()
    event.preventDefault()

    if (!this.targets.length) { return }
    [...this.targets].forEach((target) => target.classList.add(this.cssClass))
  }

  show(event) {
    event.stopPropagation()
    event.preventDefault()

    if (!this.targets.length) { return }
    [...this.targets].forEach((target) => target.classList.remove(this.cssClass))
  }

  toggle(event) {
    event.stopPropagation()
    event.preventDefault()

    if (!this.targets.length) { return }
    [...this.targets].forEach((target) => target.classList.toggle(this.cssClass))
  }

  get cssClass() { return this.cssClassValue || DEFAULT_CSS_CLASS }
  get targets() { return document.querySelectorAll(this.selectorValue) }
}
