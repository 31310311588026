import I18nMock from '@/src/i18n_mock'
import currency from 'currency.js'

const defaultConfig = {
  separator: '.',
  decimal: ',',
  symbol: "€",
  pattern: "# !"
}

export function toDelocalizedFloat(valueString) {
  if (I18nMock.locale != "en") { valueString = valueString.replaceAll(".", "").replaceAll(",", ".") }
  return parseFloat(valueString)
}

export function euro(value) {
  switch (I18nMock.locale) {
    case "en":
      return currency(value, {
        ...defaultConfig,
        separator: ',',
        decimal: '.'
      })

    default:
      return currency(value, defaultConfig)
  }
}
