import { Controller } from 'stimulus'
import BSN from 'bootstrap.native/dist/bootstrap-native.esm.min.js'

export default class extends Controller {
  static targets = ['selectAll', 'unselectAll', 'checkbox']

  connect() {
    new BSN.Dropdown(this.element.querySelector('.dropdown'))

    this.element.querySelector('.dropdown-menu').addEventListener('click', e => e.stopPropagation())
  }

  selectAll(e) {
    e.preventDefault()

    for (let checkbox of this.checkboxTargets)
      checkbox.click();

    this.syncUserChips();

    this.selectAllTarget.classList.add('is--hidden')
    this.unselectAllTarget.classList.remove('is--hidden')
  }

  unselectAll(e) {
    e.preventDefault()

    for (let checkbox of this.checkboxTargets)
      checkbox.click();

    this.syncUserChips();

    this.selectAllTarget.classList.remove('is--hidden')
    this.unselectAllTarget.classList.add('is--hidden')
  }

  removeUser(e) {
    const removeButton = e.currentTarget;
    const userId = removeButton.dataset.userId;
    const target = this.checkboxTargets.find(checkbox => checkbox.value == userId);

    target.click();
    this.syncUserChips();
  }

  checkboxChanged(e) {
    this.syncUserChips();
  }

  syncUserChips() {
    this.checkboxTargets.forEach(checkbox => {
      const userId = checkbox.value;
      const chip = this.findChipByUserId(userId);

      if (checkbox.checked) {
        chip.classList.remove('is--hidden');
      } else {
        chip.classList.add('is--hidden');
      }
    });
  }

  findChipByUserId(userId) {
    return this.element.querySelector(`.chip[data-user-id="${userId}"]`);
  }

}
