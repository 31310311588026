// app/javascript/controllers/exclusive_checkbox_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.checkboxes = this.element.querySelectorAll("input[type='checkbox']");
    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        this.uncheckOtherCheckboxes(checkbox);
      });
    });
  }

  uncheckOtherCheckboxes(clickedCheckbox) {
    this.checkboxes.forEach((checkbox) => {
      if (checkbox !== clickedCheckbox && checkbox.checked) {
        checkbox.checked = false;
      }
    });
  }
}
