import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener('change', () => {
      this.form.requestSubmit()
    })
  }

  get form() {
    return this.element.closest('form')
  }

}
