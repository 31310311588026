import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "dropdown"]

  toggleDropdown(event) {
    const dropdownId = event.currentTarget.dataset.mobileNavigationDropdown
    const dropdown = this.dropdownTargets.find(element => element.dataset.mobileNavigationDropdown === dropdownId)
    if (!dropdown) { return }

    dropdown.classList.toggle("is--expanded")
    event.currentTarget.classList.toggle("is--expanded")
  }

  toggleNavigation() {
    if (!this.hasContainerTarget) { return }

    this.containerTarget.classList.toggle("is--active")
    document.body.classList.toggle("overflow-hidden")
  }
}
