import { Controller } from 'stimulus'

const PATHS = {
  solar_module: 'solar_modules',
  inverter:     'inverters',
  battery:      'batteries',
  accessory:    'accessories',
  e_mobility:   'e_mobility_products'
}

export default class extends Controller {
  static targets = ['type', 'producerId']

  next() {
    if(this.type && this.producerId)
      Turbo.visit('/de/admin/' + PATHS[this.type] + '/new?producer_id=' + this.producerId)
  }

  get type() {
    return this.typeTarget.value
  }

  get producerId() {
    return this.producerIdTarget.value
  }
}
