import { Controller } from "stimulus"
import EventBus from "@/src/event_bus"

// This is essentially a way to trigger custom events using freshly-inserted DOM nodes.
export default class extends Controller {
  static values = {
    event: String,
    data: Object
  }

  connect() {
    if (!this.eventValue) { return }

    EventBus.emit(this.eventValue, this.dataValue)
  }
}
