import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['pane']

  open() {
    this.paneTarget.classList.remove('is--hidden')
  }

  close(e = null) {
    if(!e || e.target.classList.contains('will-close')) {
      this.paneTarget.classList.add('is--hidden')
    }
  }

  toggle() {
    this.paneTarget.classList.contains('is--hidden') ? this.open() : this.close()
  }
}
