import { Controller } from "stimulus"

import I18nMock from '@/src/i18n_mock'

const REFRESH_INTERVAL = 30000

export default class extends Controller {
  static targets = ['pane']

  open() {
    this.paneTarget.style.display = 'block'
    this.disableUpdate()
  }

  close(e) {
    this.paneTarget.style.display = 'none'
    this.enableUpdate()
  }

  toggle(e) {
    this.paneTarget.style.display !== 'block' ? this.open() : this.close()
  }

  handleOutsideClick(e) {
    if(this.paneTarget.style.display === 'block' && e.isTrusted) {

      if(e.target.matches) {
        if(!e.target.matches('#notifications-dropdown *')) this.close()
      } else {
        if(!e.target.msMatchesSelector('#notifications-dropdown *')) this.close()
      }
    }
  }

  enableUpdate() {
    this.updateInterval = setInterval(this.update.bind(this), REFRESH_INTERVAL)
  }

  disableUpdate() {
    clearInterval(this.updateInterval)
    this.updateInterval = null
  }

  update() {
    this.element.setAttribute('src', '/' + I18nMock.locale + '/notifications')
  }

  connect() {
    document.body.addEventListener('click', this.handleOutsideClick.bind(this))
    // this.enableUpdate()
  }

  disconnect() {
    document.body.removeEventListener('click', this.handleOutsideClick.bind(this))
    if(this.updateInterval) clearInterval(this.updateInterval)
  }
}
