import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']
  static values  = {
    selector: String,
    show: String
  }

  connect() {
    for(let target of this.inputTargets) {
      target.addEventListener('change', this.handleChanged.bind(this))
    }
  }

  disconnect() {
    for(let target of this.inputTargets) {
      target.removeEventListener('change', this.handleChanged.bind(this))
    }
  }

  handleChanged(e) {
    if(e.target.value == this.showValue)
      this.show()
    else
      this.hide()
  }

  hide() {
    if(!this.elements.length) return
    [...this.elements].forEach((target) => target.classList.add('is--hidden'))
  }

  show() {
    if(!this.elements.length) return
    [...this.elements].forEach((target) => target.classList.remove('is--hidden'))
  }

  get elements() { return document.querySelectorAll(this.selectorValue) }
  get value() {
    for(let target of this.inputTargets) {
      if(target.checked) return target.value
    }

    return null
  }
}
