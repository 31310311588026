import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    action: String
  }

  connect() {
    this.element.addEventListener('click', e => {
      e.preventDefault()

      const form = this.element.closest('form')
      const hiddenField = form.querySelector('input[name=_method]')

      const prevAction = form.action
      const prevMethod = form.method
      const prev_method = hiddenField.value

      form.action = this.actionValue
      form.method = 'POST'
      hiddenField.value = 'POST'

      form.requestSubmit()

      form.action = prevAction
      form.method = prevMethod
      hiddenField.value = prev_method
    })
  }
}
