// This is a minimal controller that mimics regular radio button behaviour for radio buttons with different names.
// These are usually encountered in a ransack filter form where we use them to toggle between mutually-exclusive scopes. Since ransack requires us to name the applicable element according to the scope, we can't just use the same name with different values.
import { Controller } from "stimulus"

const NODE_NAME_INPUT = "INPUT"

export default class extends Controller {
  static targets = ["radioButton"]

  updateSelection(event) {
    this.radioButtonTargets.forEach(radioButton => radioButton.checked = radioButton == event.target)
  }
}
