import { Controller } from "stimulus"
import { addClassToAll, removeClassFromAll } from '../src/helpers'

export default class extends Controller {
  static targets = ['originalContent', 'showOriginalLink', 'showTranslatedLink', 'translatedContent']

  showOriginal() {
    if (!this.hasOriginalContentTarget) { return }

    removeClassFromAll(this.originalContentTargets, 'is--hidden')
    addClassToAll(this.translatedContentTargets, 'is--hidden')
    this.showOriginalLinkTarget.classList.add('is--hidden')
    this.showTranslatedLinkTarget.classList.remove('is--hidden')
  }

  showTranslation() {
    if (!this.hasOriginalContentTarget) { return }

    addClassToAll(this.originalContentTargets, 'is--hidden')
    removeClassFromAll(this.translatedContentTargets, 'is--hidden')
    this.showOriginalLinkTarget.classList.remove('is--hidden')
    this.showTranslatedLinkTarget.classList.add('is--hidden')
  }
}
