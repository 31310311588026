import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["productTypeInput"]
  static values = { url: String }

  updateType() {
    const productType = this.productTypeInputTarget.value
    if (!productType){ return }

    const targetUrl = `${this.urlValue}?product_type=${productType}`
    Turbo.visit(targetUrl)
  }
}
