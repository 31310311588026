import TriggerSubmitController from "./trigger_submit_controller";

export default class extends TriggerSubmitController {
  save(e) {
    const volumeType = Array.from(document.querySelectorAll('.volume-type-radio')).find((element) => element.checked);
    let label = ''

    if(volumeType?.dataset?.labelIcon) {
      label = `<img src="${volumeType.dataset.labelIcon}" alt="${volumeType?.dataset?.label}" width="30" />`
    } else {
      label = volumeType?.dataset?.label
    }

    document.querySelectorAll('.price-range__quantity .input-group-text').forEach((i) => i.innerHTML = label);

    super.triggerSubmit();
  }
}
