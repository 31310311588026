import { Controller } from "stimulus"

const DEFAULT_REFRESH_INTERVAL = 30000

export default class extends Controller {
  static values = {
    src: String,
    interval: Number
  }

  update() { this.element.setAttribute("src", this.srcValue) }

  connect() { this.timeout = setInterval(() => { this.update() }, this.refreshInterval) }

  disconnect() { clearTimeout(this.timeout) }

  get refreshInterval() {
    try {
      if (this.intervalValue) { return this.intervalValue }
    } catch(e) {}

    return DEFAULT_REFRESH_INTERVAL
  }
}
