import { Controller } from 'stimulus'
import { getCSRFToken, isStringEmpty } from '../src/helpers'

export default class extends Controller {
  static targets = [ 'inputField', 'negativeIcon', 'positiveIcon' ]
  static values = {
    url: String,
    previousValue: String
  }

  async checkVatId() {
    const vatId = this.inputFieldTarget.value
    const data = { vat_id: vatId }
    if(isStringEmpty(vatId) || vatId === this.previousValue) { return }

    await fetch(this.urlValue, {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken()
      },
      redirect: 'follow',
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      this.previousValue = vatId
      this.activateStatusIcon(data && data.valid)
    })
    .catch((error) => {
      console.error(`[${this.identifier}]: ${error}`)
    })
  }

  activateStatusIcon(valid) {
    this.positiveIconTarget.classList.toggle('is--active', valid)
    this.negativeIconTarget.classList.toggle('is--active', !valid)
  }
}
