import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    customReasons: String,
    customSelector: String,
  }

  // Ensure that the form is expanded after the user has submitted an invalid form while having the custom text option selected.
  connect() { this.toggleOptionalFormSections() }

  toggleOptionalFormSections() {
    if (this.customTarget) {
      this.customTarget.classList.toggle(
        'is--hidden',
        !this.customTextReasons.includes(this.element.value)
      )
    }
  }

  get customTextReasons() { return JSON.parse(this.customReasonsValue) }
  get customTarget() { return document.querySelector(this.customSelectorValue) }
}
