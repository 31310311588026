import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['target', 'template']
  static values = {
    originalPieces: Number
  }

  connect() {
    console.log("price ranges controller connected")
  }

  add(e) {
    e.preventDefault()

    const prevRange = this.lastRange
    const quantityFrom = prevRange.querySelector('.quantity-from').value
  }

  remove(e) {
    e.preventDefault()
  }

  changed() {
  }

  get priceRanges() {
    return document.querySelectorAll('.shipping-item-wrapper')
  }

  get lastRange() {
    return this.priceRanges[this.priceRanges.length - 1]
  }
}
