import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tabContent", "tabLink"]

  changeTab(event) {
    this.tabLinkTargets.forEach(element => {
      element.classList.toggle("is--active", element == event.currentTarget)
    })

    this.tabContentTargets.forEach(element => {
      element.classList.toggle("is--active", element.matches(event.currentTarget.dataset.target))
    })
  }
}
