import { Controller } from "stimulus"

const spinnerTemplate = (text) => `
<div class="spinner-overlay">
  <div class="spinner-content">
    <div class="spinner-content__spinner"></div>
    <div class="spinner-content__text">${text}</div>
  </div>
</div>
`

export default class extends Controller {
  static values = {
    text: String,
  }

  showOverlay() {
    const modalFrame = document.querySelector('#modal-frame')
    if(!modalFrame) { return }

    modalFrame.innerHTML = spinnerTemplate(this.textValue)
  }
}
