import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "inStockFilterInput",
    "priceFilterInput",
    "quantityFilterInput",
    "searchQueryInput",
    "searchTitleInput",
    "searchUrlInput",
    "serializedStateInput"
  ]

  connect() {
    const currentUrl = `${window.location.pathname}${window.location.search}`
    const serializedState = this.serializedStateSource
    if (!serializedState) { return }

    this.serializedStateInputTarget.value = serializedState
    this.searchUrlInputTarget.value = currentUrl
    this.inStockFilterInputTarget.value = this.inStockFilterValue
    this.priceFilterInputTarget.value = this.priceFilterValue
    this.quantityFilterInputTarget.value = this.quantityFilterValue

    if (this.searchTitle) {
      this.searchTitleInputTarget.value = this.searchTitle
      this.searchQueryInputTarget.value = this.searchTitle
    }
  }

  get inStockFilterValue() {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.has("offer.in_stock") ? "1" : null
  }

  get priceFilterValue() {
    const state = JSON.parse(this.serializedStateSource)
    const quantityEntry = state.numericFilters?.find(entry => entry.startsWith("offer.price_ranges.price_per_quantity<="))
    if (!quantityEntry) { return "" }

    return quantityEntry.split("<=")[1].replace(",", "").replace(".", ",")
  }

  get quantityFilterValue() {
    const state = JSON.parse(this.serializedStateSource)
    const quantityEntry = state.numericFilters?.find(entry => entry.startsWith("offer.quantity>="))
    if (!quantityEntry) { return "" }

    return parseInt(quantityEntry.split(">=")[1])
  }

  get serializedStateSource() {
    return document.querySelector("[data-saved-search-query-state]")?.dataset?.savedSearchQueryState
  }

  get searchTitle() {
    return document.querySelector(".marketplace-search-box .offers-index-search")?.value
  }
}
