import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["image", "checkbox"];

  toggle() {
    this.checkboxTarget.click();

    if (this.checkboxTarget.checked) {
      this.imageTarget.src = this.imageTarget.dataset.checkedFlagUrl;
    } else {
      this.imageTarget.src = this.imageTarget.dataset.uncheckedFlagUrl;
    }
  }
}