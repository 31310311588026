import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'checkBoxPallets', 'checkBoxContainers', 'palletSize', 'containerSize' ]

  togglePallets() {
    if (this.checkBoxPalletsTarget.checked) {
      this.checkBoxContainersTarget.checked = false
      this.palletSizeTarget.classList.remove('is--hidden')
      this.containerSizeTarget.classList.add('is--hidden')
    } else {
      this.palletSizeTarget.classList.add('is--hidden')
    }
  }

  toggleContainers() {
    if (this.checkBoxContainersTarget.checked) {
      this.checkBoxPalletsTarget.checked = false
      this.palletSizeTarget.classList.add('is--hidden')
      this.containerSizeTarget.classList.remove('is--hidden')
    } else {
      this.containerSizeTarget.classList.add('is--hidden')
    }
  }

}
