import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    selector: String,
    inputValueRequired: Boolean
  }

  connect() {
    if (this.element.dataset?.reload) {
      document.querySelector(this.element.dataset?.reload).reload();
    }

    if (this.element.id === window.previousActiveElementId) {
      this.element.focus()
      this.element.value = this.element.value
    }
  }

  storeFocus() { window.previousActiveElementId = this.element.id }

  triggerSubmit() {
    if (!this.target) { return }
    if (this.inputValueRequiredValue && !this.element.value) { return }

    this.target.requestSubmit()
  }

  get target() {
    let target = null

    try { target = document.querySelector(this.selectorValue) } catch (e) { }
    if (!target) { target = this.element.closest('form') }
    return target
  }
}
