import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["input"]

  connect() {
    if (!this.inquiryIdFromURL) { return }
    if (!this.hasInputTarget) { return }

    // We don't simply assign the result of the comparison to the #checked attribute to prevent unselecting all radios if no match is found.
    this.inputTargets.forEach(element => {
      if (element.value === this.inquiryIdFromURL) { element.checked = true }
    })
  }

  get inquiryIdFromURL() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams.get("inquiry_id")
  }
}
