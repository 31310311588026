import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['parentType', 'parentId', 'tag']

  remove(e) {
    e.preventDefault()

    this.parentTypeTarget.value = ''
    this.parentIdTarget.value = ''

    this.tagTarget.classList.add('is--hidden')
  }
}
