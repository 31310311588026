import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    defaultSizes: Object
  }

  connect() {
    if(this.packagingTypeInput && this.widthInput && this.lengthInput) {
      this.packagingTypeInput.addEventListener('change', this.handleChange.bind(this))
    }
  }

  handleChange(e) {
    const packagingType = e.target.value

    if(this.defaultSizesValue[packagingType]) {
      this.widthInput.value = this.defaultSizesValue[packagingType].width
      this.lengthInput.value = this.defaultSizesValue[packagingType].length
      this.heightInput.value = this.defaultSizesValue[packagingType].height
    }
  }

  get packagingTypeInput() {
    return this.element.querySelector('.packaging-type-input')
  }

  get widthInput() {
    return this.element.querySelector('.box-width-input')
  }

  get lengthInput() {
    return this.element.querySelector('.box-length-input')
  }

  get heightInput() {
    return this.element.querySelector('.box-height-input')
  }
}
