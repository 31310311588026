import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    let img  = new Image()

    if(this.element.dataset.src) {
      img.src = this.element.src
    } else {
      img.srcset = this.element.dataset.srcset
    }

    img.addEventListener('load', () => {
      if(this.element.dataset.src) {
        this.element.src = this.element.dataset.src
      } else {
        this.element.srcset = this.element.dataset.srcset
      }
    })
  }
}
