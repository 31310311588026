import VisibilityToggleController from "./visibility_toggle_controller"

const DESIRED_STATE_CHECKED = "checked"
const DESIRED_STATE_UNCHECKED = "unchecked"

export default class extends VisibilityToggleController {
  static values = {
    desiredState: String,
    selector: String,
    invertedSelector: String
  }

  toggle(event) {
    if (this.isInDesiredState()) {
      this.show(event)
      this.invertedTargets.forEach((target) => target.classList.add('is--hidden'))
    } else {
      this.hide(event)
      this.invertedTargets.forEach((target) => target.classList.remove('is--hidden'))
    }
  }

  isInDesiredState() {
    switch (this.desiredStateValue) {
      case DESIRED_STATE_CHECKED:
        return this.element.checked
      case DESIRED_STATE_UNCHECKED:
        return !this.element.checked
      default:
        return false
    }
  }

  get invertedTargets() { return document.querySelectorAll(this.invertedSelectorValue) }
}
