import { Controller } from 'stimulus'
import TomSelect      from 'tom-select'

export default class extends Controller {
  static values = {
    placeholder: String
  }

  static targets = ['toggle']

  connect() {
    this.element.classList.add('ts-no-dropdown')

    this.tomSelect = new TomSelect(this.selectElement, {
      placeholder: this.placeholderValue,
      persist: false,
      createOnBlur: true,
      create: true,
      openOnFocus: false,
      allowEmptyOption: true,
      shouldLoad: () => false,
      onItemAdd:function() {
        this.setTextboxValue('')
      },
      plugins: {
        remove_button:{
          title:'Remove E-Mail'
        }
      }
    })

    this.initialValue = [...this.tomSelect.getValue()]

    this.textInput.addEventListener('input', this.handleInput.bind(this))
    this.toggleTarget.addEventListener('click', this.toggle.bind(this))
  }

  toggle(e) {
    e.target.checked ? this.enable() : this.disable()
  }

  enable() {
    this.tomSelect.enable()
  }

  disable() {
    this.tomSelect.disable()

    this.tomSelect.clear()
    this.tomSelect.addItems(this.initialValue)
  }

  handleInput() {
    const value = this.textInput.value
    const match = this.textInput.value.match(/.\S\s/)

    if(match) {
      this.tomSelect.addOption({value, text: value}, true)
      this.tomSelect.addItem(value, false)
    }
  }

  get selectElement() {
    return this.element.querySelector('select')
  }

  get textInput() {
    return document.getElementById(this.tomSelect.inputId + '-ts-control')
  }
}
