import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if(location.hash === '#faq-' + this.element.dataset.id) {
      this.element.classList.add('faq__question--open')
    }

    this.element.addEventListener('click', () => {
      this.element.classList.toggle('faq__question--open')
    })
  }
}
