import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    selector: String
  }

  connect() {
    for(const input of this.inputs) {
      input.addEventListener('change', this.handleInputChanged.bind(this))
    }
  }

  handleInputChanged(e) {
    if(e.target.checked) {
      for(const input of this.adjacentInputs(e.target.dataset.locale)) {
        if(input !== e.target) input.checked = false
      }
    }
  }

  adjacentInputs(locale) {
    return document.querySelectorAll(this.selectorValue + '[data-locale="' + locale + '"]')
  }

  get inputs() { return this.element.querySelectorAll(this.selectorValue) }
}
