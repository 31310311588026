import { baseApi } from "../base/base";

export const productTemplatesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getGeneratedDescription: builder.mutation({
      query: (data) => ({ url: '/admin/product_templates/generate', method: 'POST', body: data }),
      invalidatesTags: ['ProductTemplate']
    }),
  }),
  overrideExisting: false
})

export const { useGetGeneratedDescriptionQuery } = productTemplatesApi;
