import { Controller } from "stimulus";
import { productTemplatesApi } from '../api/admin/product_templates';
import { store } from '../api/store';

export default class extends Controller {
  static targets = ["richText"];

  async generate(e) {
    e.preventDefault();

    const description = await store.dispatch(productTemplatesApi.endpoints.getGeneratedDescription.initiate(this.getData()));

    if (this.hasRichTextTarget) {
      this.richTextTarget.editor.loadHTML(description?.data?.description);
    }
  }

  getData() {
    const form = document.querySelector('#product_type').closest('form');
    const formData = new FormData(form);
    formData.delete('_method');

    return formData;
  }
}
