import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkboxAll', 'checkbox', 'toggleElement', 'enableElement', 'selectedAmount']

  initialize() {
    this.toggle  = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  connect() {
    this.checkboxAllTarget.addEventListener('change', this.toggle)

    for(const checkbox of this.checkboxTargets) {
      checkbox.addEventListener('change', this.refresh)
    }
  }

  toggle(e) {
    e.preventDefault()

    for(const checkbox of this.checkboxTargets) {
      checkbox.checked = e.target.checked
    }

    this.setSelectedAmount()

    if(e.target.checked) {
      this.showElements()
      this.enableElements()
    } else {
      this.hideElements()
      this.disableElements()
    }
  }

  refresh(e) {
    e.preventDefault()

    this.setSelectedAmount()

    if(this.allChecked) {
      this.checkboxAllTarget.checked = true
      this.showElements()
      this.enableElements()

      return
    }

    if(this.someChecked) {
      this.checkboxAllTarget.checked = false
      this.showElements()
      this.enableElements()

      return
    }

    this.hideElements()
    this.disableElements()
  }

  showElements() {
    for(const element of this.toggleElementTargets) element.classList.remove('is--hidden')
  }

  hideElements() {
    for(const element of this.toggleElementTargets) element.classList.add('is--hidden')
  }

  enableElements() {
    for(const element of this.enableElementTargets) element.disabled = false
  }

  disableElements() {
    for(const element of this.enableElementTargets) element.disabled = true
  }

  setSelectedAmount() {
    if (!this.hasSelectedAmountTarget) { return }

    if(this.someChecked) {
      this.selectedAmountTarget.classList.remove('is--hidden')
    } else {
      this.selectedAmountTarget.classList.add('is--hidden')

      return
    }

    let sum = 0.0

    for(const element of this.checkedElements) {
      const checkedAmountElement = element.closest('tr').getElementsByClassName('inquiry-amount')[0]
      if (!checkedAmountElement) { continue }

      const amount = parseFloat(checkedAmountElement.dataset.amount)
      if(!amount) { continue }

      sum += amount
    }

    this.selectedAmountTarget.innerHTML = "Selected invoices: € " + sum.toFixed(2)
  }

  get someChecked() {
    return this.checkboxTargets.some(checkbox => checkbox.checked)
  }

  get allChecked() {
    return this.checkboxTargets.every(checkbox => checkbox.checked)
  }

  get checkedElements() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }
}
