import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.onChange)
  }

  disconnect() {
    this.element.removeEventListener('change', this.onChange)
  }

  onChange(e) {
    if(e.target.value === 'whitelist' || e.target.value === 'blacklist')
      document.querySelectorAll('.offer-region-list').forEach(el => el.classList.remove('is--hidden'))
    else
      document.querySelectorAll('.offer-region-list').forEach(el => el.classList.add('is--hidden'))
  }
}
