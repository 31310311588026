import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit', 'reset']

  connect() {
    this.disableSubmit()

    this.initialData = {}
    this.hasChanged = false

    this.saveData()
  }

  changed() {
    if(this.hasChanged) return

    this.hasChanged = true

    this.enableSubmit()
  }

  reset(e) {
    e.preventDefault()

    if(!this.hasChanged) return

    this.hasChanged = false

    this.restoreData()

    this.disableSubmit()
  }

  enableSubmit() {
    this.submitTarget.disabled = false
    if(this.resetTarget) this.resetTarget.classList.remove('is--hidden')
  }

  disableSubmit() {
    this.submitTarget.disabled = true
    if(this.resetTarget) this.resetTarget.classList.add('is--hidden')
  }

  saveData() {
    this.element.querySelectorAll('input, textarea, select').forEach(input => {
      if(input.id !== '') {
        this.initialData[input.id] = input.value

        if(input.type === 'checkbox') {
          this.initialData[input.id] = input.checked
          input.addEventListener('change', this.changed.bind(this))
        } else {
          this.initialData[input.id] = input.value
          input.addEventListener('keydown', this.changed.bind(this))
          input.addEventListener('change', this.changed.bind(this))
        }
      }
    })
  }

  restoreData() {
    this.element.querySelectorAll('input, textarea, select').forEach(input => {
      if(input.id in this.initialData) {
        if(input.type === 'checkbox') {
          input.checked = this.initialData[input.id]
        } else if(input.classList.contains('flatpickr-input')) {
          input._flatpickr.setDate(new Date(this.initialData[input.id]))
        } else {
          input.value = this.initialData[input.id]
        }
      }
    })
  }
}
