import { Controller } from "stimulus"
import { isEscapeKey } from "../src/helpers"

export default class extends Controller {
  static targets = ["closeButton"]

  connect() {
    this.closeModalIfDeepest = this.closeModalIfDeepest.bind(this)
    this.handleEscapeButton = this.handleEscapeButton.bind(this)

    const currentUrl = new URL(window.location.href)
    window.history.pushState({}, "", currentUrl)

    window.addEventListener("popstate", this.closeModalIfDeepest)
    document.addEventListener("keydown", this.handleEscapeButton)
  }

  disconnect() {
    window.removeEventListener("popstate", this.closeModalIfDeepest)
    window.removeEventListener("keydown", this.handleEscapeButton)
  }

  closeModal() {
    const modalFrame = document.querySelector("#modal-frame")
    if(!modalFrame) { return }

    modalFrame.innerHTML = ""
    modalFrame.src = null
  }

  // We generally have two different kinds of modals: ones that close using JS and ones that close using a link in combination with Turbo Streams. The JS-variant is simple and can just be discarded on close. The link-variant is usually not opened in the generic modal placeholder but temprarily replaces the button that opened it (while breaking out of its layout constraints to look like a regular modal). When its close button is clicked, the Turbo Steam response will replace the original opening trigger, usually updating some "currently selected:"-type display in the process.
  // Additionally, modals can be nested. In this case, every one has their own modal controller and has to decide if they are the deepes one. If so, they can trigger their close link, otherwise they don't do anything.
  closeModalIfDeepest(event) {
    if (!this.isDeepestModal) { return }

    // Fun times: When replacing an open modal with another using a link within the modal's Turbo Frame, the old one doesn't get disconnected, even though it is no longer attached to the DOM. So we have to explicity check for that case and don't do anything if this applies to us. The modal *will* be finally disconnected once the parent modal closes, so there won't be any leakage afterwards.
    if (!document.body.contains(this.element)) {
      this.element.remove()
      return
    }

    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    if (this.hasCloseButtonTarget) {
      this.closeButtonTarget.click()
    } else {
      this.closeModal()
    }

    document.MODAL_HISTORY_COUNTER--
  }

  handleEscapeButton(event) {
    if (isEscapeKey(event.key)) { this.closeModalIfDeepest(event) }
  }

  get isDeepestModal() {
    return !this.element.querySelector(".card-modal-wrapper")
  }
}
