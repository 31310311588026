import { Controller } from 'stimulus'

const TRIGGER_DELAY_AFTER_INPUT_IN_MS = 450
const UNIT_KW = "kw"
const UNIT_PIECES = "pcs"
const UNIT_PALLETS = "pallets"
const UNIT_CONTAINERS = "containers"
const VALID_UNITS = [UNIT_KW, UNIT_PIECES, UNIT_PALLETS, UNIT_CONTAINERS]

const spinnerTemplate = `
<div class="spinner-overlay is--child">
  <div class="spinner-content">
    <div class="spinner-content__spinner"></div>
  </div>
</div>
`

export default class extends Controller {
  static targets = [
    "input",
    "trustServiceInput",
    "unitSelect"
  ]

  // Lifecycle

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.currentQuantity = this.quantity
    this.currentTrustService = this.trustService
    this.currentUnit = this.unit
    this.triggerTimeout = null
  }

  // Event handlers

  enqueueQuantityUpdate() {
    if (this.triggerTimeout) { window.clearTimeout(this.triggerTimeout) }

    this.triggerTimeout = window.setTimeout(() => { this.updateQuantity() }, TRIGGER_DELAY_AFTER_INPUT_IN_MS)
  }

  triggerTrustServiceUpdate() {
    if (this.triggerTimeout) { window.clearTimeout(this.triggerTimeout) }

    this.updateQuantity()
  }

  // Actions

  updateQuantity() {
    if (!this.haveAnyValuesChanged()) { return }

    const newQuantity = this.quantity
    const newTrustService = this.trustService
    const newUnit = this.unit

    this.currentQuantity = newQuantity
    this.currentTrustService = newTrustService
    this.currentUnit = newUnit

    const params = new URLSearchParams(window.location.search)

    if (newQuantity) {
      params.set("filter[quantity]", newQuantity)
      params.set("filter[unit]", newUnit)
    } else {
      params.delete("filter[quantity]")
      params.delete("filter[unit]")
    }

    if (newTrustService) {
      params.set("filter[trust_service]", true)
    } else {
      params.delete("filter[trust_service]")
    }

    const newPath = `${window.location.pathname}?${params.toString()}`

    history.replaceState({}, "", newPath)
    this.showSpinner()
    Turbo.visit(newPath, {frame: "product-offers"})
  }

  showSpinner() {
    const spinnerParent = document.querySelector("[data-quantity-filter-spinner-parent]")
    if (!spinnerParent) { return }

    spinnerParent.insertAdjacentHTML("afterbegin", spinnerTemplate)
  }

  // Predicate methods

  haveAnyValuesChanged() {
    if (this.quantity !== this.currentQuantity) { return true }
    if (this.unit !== this.currentUnit) { return true }
    if (this.trustService !== this.currentTrustService) { return true }

    return false
  }

  // Getters

  get quantity() {
    try {
      return parseInt(this.inputTarget.value, 10)
    } catch (e) {
      return null
    }
  }

  get trustService() {
    try {
      return this.trustServiceInputTarget.checked
    } catch (e) {
      return false
    }
  }

  get unit() {
    if (!this.hasUnitSelectTarget) { return UNIT_PIECES }

    const selection = this.unitSelectTarget.value
    if (!VALID_UNITS.includes(selection)) { return UNIT_PIECES }

    return selection
  }
}
